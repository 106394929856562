import React, { useState } from 'react'
import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';
import { fade } from '@material-ui/core/styles';
import { Link } from "gatsby";
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Fuse from "fuse.js";
// import SideDrawer from '../SideDrawer';
import { CardViewModel, NavItem } from '../../../../gatsby/types';

interface Props {
    navItems: NavItem[],
    primaryIndex: number,
    secondaryIndex: number,
    gameSlug: string,
    aidSlug: string,
    navTitle: string,
    searchCards: Array<CardViewModel>
}

const CustomTab = styled(Tab)`
    min-width: 50px !important;
    text-transform: none;
`

const HeadTitle = styled(Typography)`
    display: none;
    ${props => props.theme.breakpoints.up("sm")} {
        display: block;
    }
`

const Search = styled.div`
    position: relative;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    background-color: ${props => fade(props.theme.palette.common.white, 0.15)};
    &:hover: {
        background-color: ${props => fade(props.theme.palette.common.white, 0.25)};
    }
    margin-right: ${props => props.theme.spacing(2)}px;
    margin-left: 0px;
    width: 100%;
    ${props => props.theme.breakpoints.up("sm")} {
        margin-left: ${props => props.theme.spacing(3)}px;
        width: auto;
    }
`;

const SearchIconWrapper = styled.div`
    padding: ${props => props.theme.spacing(0, 2)};
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SearchInput = styled(InputBase)`
    color: inherit;
    padding: ${props => props.theme.spacing(1, 1, 1, 0)};
    // vertical padding + font size from searchIcon
    padding-left: calc(1em + ${props => props.theme.spacing(4)}px);
    transition: ${props => props.theme.transitions.create('width')};
    width: 100%;
    ${props => props.theme.breakpoints.up('md')} {
        width: 60ch;
    }
`

const SearchResult = styled.div`
    position: absolute;
    z-index: 1;
    width: 100%;
    cursor: pointer;
`;

const SearchResultItem = styled.div`
    padding: 6px;
`;

const GatsbyLink = styled(Link)`
    text-decoration: none;
`

interface GetPrimaryLinkProps {
    gameSlug: string,
    aidSlug: string,
    primarySlug: string,
    secondarySlug: string | null
}

const getPrimaryLink = ({ gameSlug, aidSlug, primarySlug, secondarySlug }: GetPrimaryLinkProps) =>
    secondarySlug != null
        ? `/${gameSlug}/${aidSlug}/${primarySlug}/${secondarySlug}/`
        : `/${gameSlug}/${aidSlug}/${primarySlug}/`;

interface GetSecondaryLinkProps {
    gameSlug: string,
    aidSlug: string,
    primarySlug: string | null,
    secondarySlug: string | null
}

const getSecondaryLink = ({ gameSlug, aidSlug, primarySlug, secondarySlug }: GetSecondaryLinkProps) =>
    `/${gameSlug}/${aidSlug}/${primarySlug}/${secondarySlug}/`;

const getSecondarySlug = (secondaries: { slug: string }[]) =>
    secondaries.length != 0 ? secondaries[0].slug : null;

export default (props: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [state, setState] = useState({
        inputText: '',
        isDrawerOpen: true
    });

    const toggleDrawer = () =>
        setState({ ...state, isDrawerOpen: !state.isDrawerOpen })

    const setInputText = input => setState({ ...state, inputText: input });

    const {
        primaryIndex,
        secondaryIndex,
        navItems,
        gameSlug,
        aidSlug,
        navTitle,
        searchCards
    } = props;

    const primary = navItems.length !== 0 ? navItems[primaryIndex] : null
    const secondaries = primary?.secondaries || null;

    const options = {
        includeScore: true,
        keys: ['name']
    }

    const fuse = new Fuse(searchCards, options);

    const filteredTiles: Array<any> = fuse.search(state.inputText)
        .sort((item1: any, item2: any) => item1.score - item2.score)
        .map(i => i.item)
        .slice(0, 10);

    const close = () => setTimeout(() => setIsOpen(false), 200);

    return (
        <>
            <AppBar position="sticky" component="header">
                <Paper square={true} component="nav">
                    <Toolbar>
                        <GatsbyLink to="/">
                            <IconButton
                                color="default"
                                edge="start"
                                aria-label="menu">
                                <HomeIcon />
                            </IconButton>
                        </GatsbyLink>
                        {/* <SideDrawer
                            navItems={navItems}
                            open={state.isDrawerOpen}
                            onClose={toggleDrawer} /> */}
                        <HeadTitle variant="span" noWrap>
                            {navTitle}
                        </HeadTitle>
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <SearchInput
                                onFocus={() => setIsOpen(true)}
                                onBlur={close}
                                type="text"
                                margin="dense"
                                onChange={ev => setInputText(ev.target.value)}
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                            {isOpen && <SearchResult>
                                <Paper variant="outlined">
                                    {filteredTiles.map((item, index) => (
                                        <GatsbyLink
                                            onClick={() => setIsOpen(false)}
                                            to={item.url}
                                            key={index}>
                                            <SearchResultItem>
                                                <Typography variant="body1" component="div" color="textPrimary">
                                                    {item.name}
                                                </Typography>
                                                <Typography variant="caption" component="div" color="textPrimary">
                                                    {item.aidCaption}
                                                </Typography>
                                            </SearchResultItem>
                                        </GatsbyLink>
                                    ))}
                                </Paper>
                            </SearchResult>}
                        </Search>
                    </Toolbar>
                    {navItems.length > 0 && <Tabs value={primaryIndex}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth">
                        {navItems.map((item, index) =>
                        (<CustomTab
                            key={index}
                            component={Link}
                            to={getPrimaryLink({
                                gameSlug,
                                aidSlug,
                                primarySlug: item.slug,
                                secondarySlug: getSecondarySlug(item.secondaries)
                            })}
                            value={index}
                            label={item.name}
                        />)
                        )}
                    </Tabs>}
                    {navItems.length > 0 && (secondaries || []).length > 0 && <Tabs value={secondaryIndex}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth">
                        {(secondaries || []).map((navItem: any, index: number) =>
                        (<CustomTab
                            key={index}
                            component={Link}
                            to={getSecondaryLink({
                                gameSlug,
                                aidSlug,
                                primarySlug: primary && primary.slug,
                                secondarySlug: navItem.slug
                            })}
                            value={index}
                            label={navItem.name}
                        />)
                        )}
                    </Tabs>}
                </Paper>
            </AppBar>
        </>
    )
}
