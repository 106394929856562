import React from 'react'
import styled from 'styled-components';
import { Helmet } from "react-helmet";
import Grid from '@material-ui/core/Grid';
import MaterialLink from '@material-ui/core/Link';
import ReactMarkdown from 'react-markdown'
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import RemoveMd from 'remove-markdown';
import QuickSearchNavBar from '../../components/item/NavBar';
import QuickSearchCard from '../../components/item/Card';
import PageLayout from '../../layout/page-layout';
import { CardPageViewModel } from '../../../gatsby/types';

const TileDescription = styled(ReactMarkdown)`
  text-align: left;
  padding-bottom: 12px;
  font-size: 1.25em;
  font-family: Roboto;
  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

const MainBody = styled.main`
  padding-left: 20px;
  padding-right: 20px;
  overflow-x: hidden;
`

const FooterBody = styled.footer`
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  overflow-x: hidden;
`

const HeadingContainer = styled.div`
  margin-top: 1em;
`

const CustomH1 = styled.h1`
  font-size: 1.75em;
  display: inline;
`

interface Props {
  pathContext: {
    page: CardPageViewModel
  },
  location: any
}

const buildH1Title = (gameName: string, aidName: string, primaryTag: string, secondaryTag: string) => {
  if (secondaryTag != '') return `${gameName} ${aidName} - ${primaryTag} - ${secondaryTag}`;

  if (primaryTag != '') return `${gameName} ${aidName} - ${primaryTag}`;

  return `${gameName} ${aidName}`;
}

const buildH1Text = (aidName: string, primaryTag: string, secondaryTag: string) => {
  if (secondaryTag != '') return `${aidName} - ${primaryTag} - ${secondaryTag}`;

  if (primaryTag != '') return `${aidName} - ${primaryTag}`;

  return `${aidName}`;
}

const markdownRenderers = {
  link: (props: any) => (
    <MaterialLink color="primary" href={props.href}>{props.children}</MaterialLink>
  )
}

export default (props: Props) => {
  const {
    aidName,
    gameName,
    primaryName,
    primarySlug,
    secondaryName,
    secondarySlug,
    gameSlug,
    aidSlug,
    cards,
    navItems,
    primaryIndex,
    secondaryIndex,
    siteUrl,
    gameFooterComment,
    aidFooterComment,
    searchCards
  } = props.pathContext.page;

  const {
    hash,
    state
  } = props.location;

  const isInternalLink = (state || {}).isInternalLink;

  const h1Title = buildH1Title(gameName, aidName, primaryName, secondaryName);
  const h1Text = buildH1Text(aidName, primaryName, secondaryName);

  const cannonical = `${siteUrl}/${gameSlug}/${aidSlug}/${primarySlug}/${secondarySlug}/`;

  const metaDescription = cards.length != 0 ? RemoveMd(cards[0].description) : '';

  return (
    <PageLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{h1Title}</title>
        {cards && <meta name="description" content={metaDescription} />}
        <link rel="canonical" href={cannonical} />
        <html lang="en" />
      </Helmet>
      <QuickSearchNavBar
        navItems={navItems}
        gameSlug={gameSlug}
        aidSlug={aidSlug}
        primaryIndex={primaryIndex}
        secondaryIndex={secondaryIndex}
        navTitle={h1Text}
        searchCards={searchCards}
      />
      <MainBody>
        <HeadingContainer>
          <CustomH1>{h1Text}</CustomH1>
        </HeadingContainer>
        <Grid container spacing={3}>
          {cards.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} component="article">
              <QuickSearchCard powerTile={card} activeHash={hash} isInternalLink={isInternalLink} />
            </Grid>
          ))}
        </Grid>
        <Box my={3}>
          <Divider variant="middle" />
        </Box>
      </MainBody>
      <FooterBody>
        <HeadingContainer>
          {gameFooterComment && <TileDescription renderers={markdownRenderers}>
            {gameFooterComment}
          </TileDescription>}
          {aidFooterComment && <TileDescription renderers={markdownRenderers}>
            {aidFooterComment}
          </TileDescription>}
        </HeadingContainer>
      </FooterBody>
    </PageLayout>
  )
}
