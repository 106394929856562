import React from 'react'
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import List from '@material-ui/core/List';
import Grow from '@material-ui/core/Grow';
import MaterialLink from '@material-ui/core/Link';
import { Link, navigate } from "gatsby"
import Divider from '@material-ui/core/Divider';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CardViewModel } from '../../../../gatsby/types';

const CustomCardHeader = styled(CardHeader)`
  margin-bottom: 0px;
  margin-top: 0px;
`

const CustomDialogContent = styled(CardContent)`
  padding-top: 0px,
  padding-bottom: 0px;
`

const GatsbyLink = styled(Link)`
  text-decoration: none;
`

const CustomCardContent = styled(CardContent)`
  padding-top: 0px;
  padding-bottom: 0px;
  height: 120px;
  -webkit-mask-image: linear-gradient(to bottom, black 10%, transparent 100%);
  mask-image: linear-gKradient(to bottom, black 10%, transparent 100%);
`

const TileDescription = styled(ReactMarkdown)`
  text-align: left;
  padding-bottom: 12px;
  font-size: 1.25em;
  font-family: Roboto;
  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

const FaqDescription = styled(ReactMarkdown)`
text-align: left;
padding-bottom: 12px;
font-size: 1em;
font-family: Roboto;
p {
  margin-top: 10px;
  margin-bottom: 10px;
}
`

const AnchorTag = styled.a`
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
`

const QuestionText = styled(Typography)`
  font-weight: 900;
`

interface Props {
  powerTile: CardViewModel,
  activeHash: string,
  isInternalLink: boolean
}

export default ({ powerTile, activeHash, isInternalLink }: Props) => {
  const cardSize = powerTile.image ? '400px' : '200px';

  const cleanedHashed = activeHash.replace('#', '');

  const navigateToParent = () => isInternalLink ? navigate(-1) : navigate(powerTile.pageUrl);

  return (
    <>
      <Card variant="outlined">
        <AnchorTag id={powerTile.slug}></AnchorTag>
        <Dialog open={cleanedHashed == powerTile.slug} fullScreen TransitionComponent={Grow}>
          <AppBar style={{ position: 'relative' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={navigateToParent} aria-label="close">
                <ArrowBack />
              </IconButton>
              <Typography variant="h6">
                {powerTile.name}
              </Typography>
            </Toolbar>
          </AppBar>
          <List>
            {powerTile.image && <CardMedia image={powerTile.image} component="img" style={{ height: '200px', objectFit: 'contain' }} />}
            {powerTile.description &&
              <CustomDialogContent>
                <TileDescription>
                  {powerTile.description.split('\n').filter(t => t != '').join('\n\r')}
                </TileDescription>
              </CustomDialogContent>}
            {powerTile.references && powerTile.references.length != 0 &&
              <CustomDialogContent>
                <Typography variant="h4" component="h3">FAQs</Typography>
                {powerTile.references.map(ref => (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{ref.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FaqDescription>{ref.description}</FaqDescription>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </CustomDialogContent>
            }
          </List>
        </Dialog>
        <CardActionArea style={{ height: cardSize }}>
          <GatsbyLink to={`#${powerTile.slug}`} state={{ isInternalLink: true }}>
            <MaterialLink
              component="span"
              style={{ textDecoration: 'none' }}
              color="textPrimary">
              {powerTile.name && <CustomCardHeader title={powerTile.name} component="h2" />}
              {powerTile.image && <CardMedia image={powerTile.image} component="img" style={{ height: '200px', objectFit: 'contain' }} />}
              {powerTile.description &&
                <CustomCardContent>
                  <TileDescription>
                    {powerTile.description.split('\n').filter(t => t != '').join('\n\r')}
                  </TileDescription>
                </CustomCardContent>}
            </MaterialLink>
          </GatsbyLink>
        </CardActionArea>
      </Card>
    </>
  )
}
